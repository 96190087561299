import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./home/home.module').then(m => m.HomePageModule),
	},
	{
		path: 'referral/:id',
		loadChildren: () =>
			import('./referral/referral.module').then(m => m.ReferralPageModule),
	},
	{
		path: 'referral/:id/:courseType',
		loadChildren: () =>
			import('./referral/referral.module').then(m => m.ReferralPageModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
